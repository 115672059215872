// Generated by Framer (c60b0a0)

import { addFonts, addPropertyControls, ComponentViewportProvider, ControlType, cx, CycleVariantState, getFonts, useComponentViewport, useLocaleInfo, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion, MotionConfigContext } from "framer-motion";
import * as React from "react";
import { Icon as Material } from "https://framerusercontent.com/modules/6Ldpz1V0DkD45gXvi67I/PCgBX5d6MdQT7E7nhdXn/Material.js";
import * as localizedValues from "./hzf80xCZC-0.js";
const MaterialFonts = getFonts(Material);

const enabledGestures = {qB7cYcLjr: {hover: true}};

const cycleOrder = ["qB7cYcLjr", "bOWB56fb9"];

const serializationHash = "framer-XhycR"

const variantClassNames = {bOWB56fb9: "framer-v-1b0qhzg", qB7cYcLjr: "framer-v-7j5ji7"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const valuesByLocaleId = {ZULQJWnvF: localizedValues}

const getLocalizedValue = (key, locale) => {
while (locale) {
const values = valuesByLocaleId[locale.id]
if (values) {
const value = values[key]
if (value) {
return value
}
}
locale = locale.fallback
}
}

const transition1 = {delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], type: "tween"}

const Transition = ({value, children}) => {
const config = React.useContext(MotionConfigContext)
const transition = value ?? config.transition
const contextValue = React.useMemo(() => ({ ...config, transition }), [JSON.stringify(transition)])
return <MotionConfigContext.Provider value={contextValue}>{children}</MotionConfigContext.Provider>
}

const Variants = motion(React.Fragment)

const humanReadableVariantMap = {"Variant 1": "qB7cYcLjr", "Variant 2": "bOWB56fb9"}

const getProps = ({height, id, width, ...props}) => { return {...props, variant: humanReadableVariantMap[props.variant] ?? props.variant ?? "qB7cYcLjr"} }

const createLayoutDependency = (props, variants) => {if (props.layoutDependency) return variants.join('-') + props.layoutDependency
return variants.join('-')}

export interface Props extends React.HTMLAttributes<HTMLDivElement> { style?: Record<string, unknown>;className?: string;layoutId?: string | number;variant?: keyof typeof humanReadableVariantMap; }

const Component = React.forwardRef<HTMLDivElement, Props>(function(props, ref) {

const { activeLocale, setLocale } = useLocaleInfo()

const {style, className, layoutId, variant, ...restProps} = getProps(props)

const {baseVariant, classNames, clearLoadingGesture, gestureHandlers, gestureVariant, isLoading, setGestureState, setVariant, variants} = useVariantState({cycleOrder, defaultVariant: "qB7cYcLjr", enabledGestures, variant, variantClassNames})

const layoutDependency = createLayoutDependency(props, variants)

const ref1 = React.useRef<HTMLElement>(null)

const isDisplayed = () => {
if (baseVariant === "bOWB56fb9") return false
return true
}

const defaultLayoutId = React.useId()

const sharedStyleClassNames = []

const componentViewport = useComponentViewport()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<Variants animate={variants} initial={false}><Transition value={transition1}><motion.div {...restProps} {...gestureHandlers} className={cx(serializationHash, ...sharedStyleClassNames, "framer-7j5ji7", className, classNames)} data-framer-name={"Variant 1"} layoutDependency={layoutDependency} layoutId={"qB7cYcLjr"} ref={ref ?? ref1} style={{background: "linear-gradient(180deg, rgb(227, 102, 146) -6%, var(--token-1f6fdd3b-06fb-44b0-9f19-7f6a24d6a4b6, rgb(199, 53, 103)) /* {\"name\":\"Pink\"} */ 37%, var(--token-390bfe2d-de2e-4865-98aa-66e278f968be, rgb(0, 84, 148)) /* {\"name\":\"Blue\"} */ 74%, rgb(52, 132, 194) 113.00000000000001%)", borderBottomLeftRadius: 10, borderBottomRightRadius: 10, borderTopLeftRadius: 10, borderTopRightRadius: 10, ...style}} {...addPropertyOverrides({"qB7cYcLjr-hover": {"data-framer-name": undefined}, bOWB56fb9: {"data-framer-name": "Variant 2"}}, baseVariant, gestureVariant)}>{isDisplayed() && (<ComponentViewportProvider ><motion.div className={"framer-1jgk9q-container"} layoutDependency={layoutDependency} layoutId={"BF_HKdl_8-container"} style={{rotateX: 0, rotateY: 0, skewX: 0, skewY: 0}} variants={{"qB7cYcLjr-hover": {rotateX: 15, rotateY: 15, skewX: 10, skewY: 10, transformPerspective: 2386}}}><Material color={"rgb(255, 255, 255)"} height={"100%"} iconSearch={"Home"} iconSelection={"Workspaces"} iconStyle15={"Filled"} iconStyle2={"Filled"} iconStyle7={"Filled"} id={"BF_HKdl_8"} layoutId={"BF_HKdl_8"} mirrored={false} selectByList style={{height: "100%", width: "100%"}} width={"100%"}/></motion.div></ComponentViewportProvider>)}</motion.div></Transition></Variants>
</LayoutGroup>)

});

const css = ["@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-XhycR.framer-8ey3jh, .framer-XhycR .framer-8ey3jh { display: block; }", ".framer-XhycR.framer-7j5ji7 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: 400px; justify-content: center; overflow: hidden; padding: 0px; position: relative; width: 400px; will-change: var(--framer-will-change-override, transform); }", ".framer-XhycR .framer-1jgk9q-container { flex: none; height: 61%; position: relative; width: 61%; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-XhycR.framer-7j5ji7 { gap: 0px; } .framer-XhycR.framer-7j5ji7 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-XhycR.framer-7j5ji7 > :first-child { margin-left: 0px; } .framer-XhycR.framer-7j5ji7 > :last-child { margin-right: 0px; } }", ".framer-XhycR.framer-v-1b0qhzg.framer-7j5ji7 { cursor: unset; }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 400
 * @framerIntrinsicWidth 400
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["fixed","fixed"]},"bOWB56fb9":{"layout":["fixed","fixed"]},"TpyCKNh67":{"layout":["fixed","fixed"]}}}
 * @framerImmutableVariables true
 * @framerDisplayContentsDiv false
 * @framerComponentViewportWidth true
 */
const Framerhzf80xCZC: React.ComponentType<Props> = withCSS(Component, css, "framer-XhycR") as typeof Component;
export default Framerhzf80xCZC;

Framerhzf80xCZC.displayName = "FeaturesHoverStyle";

Framerhzf80xCZC.defaultProps = {height: 400, width: 400};

addPropertyControls(Framerhzf80xCZC, {variant: {options: ["qB7cYcLjr", "bOWB56fb9"], optionTitles: ["Variant 1", "Variant 2"], title: "Variant", type: ControlType.Enum}} as any)

addFonts(Framerhzf80xCZC, [{explicitInter: true, fonts: []}, ...MaterialFonts], {supportsExplicitInterCodegen: true})